.NavbarItems{
    background: #302f2f;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
}

.fa-caret-down, .fa-caret-up{
    margin-left: 0.3rem;
}

.nav-menu{
    display: grid;
    grid-template-columns: repeat(7, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    justify-content: end;
}

.nav-link, .nav-dropdown{
    color: whitesmoke;
    text-decoration: none;
    padding: 0.5rem 1rem;
    background: transparent;
}

.nav-dropdown:hover{
    background-color: #5b5b5b;
    border-radius: 4px;
    transition: all 0.2s ease-out;
    cursor: default;
}

.nav-link:hover{
    background-color: #5b5b5b;
    border-radius: 4px;
    transition: all 0.2s ease-out;
}

.active-navbar-item{
    border-bottom: 2px solid #017241;
}

.fa-bars, .fa-times{
    color: whitesmoke;
}

.menu-icon{
    display:none;
}

@keyframes dropdown-appear {
    0%   {opacity: 0%;}
    100% {opacity: 100%;}
}

@media screen and (min-width: 1051px){
    .NavbarItems{
        position: sticky;
        top: 0;
        z-index: 1;
    }
}

@media screen and (max-width: 1050px){
    .NavbarItems{
        position: relative;
        align-items: flex-start;
    }

    .nav-menu{
        display: flex;
        flex-direction: column;
        width: 100%;
        position: absolute;
        top: 70px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .nav-menu.active{
        background: #302f2f;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }

    .nav-link, .nav-dropdown, .drop-nav-link, .drop-dropdown{
        text-align: center;
        padding: 1.8rem !important;
        width: 100%;
        display:table;
    }

    .drop-nav-link, .drop-dropdown{
        animation-name: dropdown-appear;
        animation-duration: 1s;
    }

    .drop-dropdown, .drop-nav-link{
        color: whitesmoke;
        width: 100% !important;
    }

    .nav-link:hover, .nav-dropdown:hover, .drop-nav-link:hover, .drop-dropdown:hover{
        background-color: #5b5b5b !important;
        border-radius: 0;
        cursor: pointer;
    }

    .menu-icon{
        display:block;
        position:absolute;
        top:0;
        left:0;
        transform: translate(100%, 70%);
        font-size: 1.8rem;
        cursor: pointer;
    }
}