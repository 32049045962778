* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #221f1f;
}

.App {
  text-align: center;
  background-color: #221f1f;
}

.App-logo {
  height: 20vmin;
  background-color: #017241;
}

.App-header {
  background-color: #017241;
  align-items: center;
  justify-content: top;
  font-size: calc(10px + 2vmin);
  color: white;
}

.my-container{
  padding-left: 200px;
  padding-right: 200px;
}

h1{
  text-align: left;
  font-size: 1.9rem;
  color: whitesmoke;
  border-bottom: 2px solid #017241;
  margin-left: 200px;
  margin-right: 200px;
  margin-top: 30px;
  padding-bottom: 5px;
  background: transparent;
}

h2{
  text-align: left;
  font-size: 1.8rem;
  color: whitesmoke;
  margin-left: 200px;
  margin-right: 200px;
  margin-top: 30px;
  padding-bottom: 5px;
  background: transparent;
}

p{
  text-align: left;
  font-size: 1.2rem;
  color: whitesmoke;
  margin-left: 200px;
  margin-right: 200px;
  margin-top: 25px;
  background: transparent;
}

.mail-to{
  text-align: left;
  font-size: 1.2rem;
  color: whitesmoke;
  margin-top: 30px;
  text-decoration: none;
  background: transparent;
}

.link{
  text-align: left;
  font-size: 1.2rem;
  color: whitesmoke;
  margin-top: 30px;
}

td, th{
  background: transparent;
}

@media screen and (max-width: 1050px){
  h1{
    text-align: left;
    font-size: 1.5rem;
    color: whitesmoke;
    border-bottom: 2px solid #017241;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 20px;
    padding-bottom: 5px;
  }

  h2{
    text-align: left;
    font-size: 1.4rem;
    color: whitesmoke;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 20px;
    padding-bottom: 5px;
  }

  p{
    text-align: left;
    font-size: 1.0rem;
    color: whitesmoke;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 15px;
  }

  .my-container{
    padding-left: 30px;
    padding-right: 30px;
  }

  .mail-to{
    text-align: left;
    font-size: 1.0rem;
    color: whitesmoke;
    margin-top: 20px;
  }

  .link{
    text-align: left;
    font-size: 1.0rem;
    color: whitesmoke;
    margin-top: 30px;
  }

  td, th{
    background: transparent;
    font-size: 0.9rem;
  }
}