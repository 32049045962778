.mannschaftsfoto{
    margin-top: 20px;
    height: 50vmin;
}

.player-picture{
    max-width: 100%;
    height: auto;
    margin-top: 50px;
    background:transparent;
}

.player-name{
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 10px;
    text-align: center;
}

.role{
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 3px;
    text-align: center;
    font-weight: bolder;
}

.widget{
    padding-top: 20px;
    width: 100%;
    height: 634px;
}

.widget-fußball-damen{
    height: 398px;
}

@media screen and (max-width: 1050px){
    .player-name, .role, .mobile-number{
        font-size: 0.8rem;
    }

    .player-picture{
        margin-top: 20px;
    }

    .widget{
        height: 400px;
    }
}