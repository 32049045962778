.dropdown-menu{
    width: 220px;
    position: absolute;
    list-style: none;
    text-align: start;
    padding-top: 9px;
    transition: all 0.5s ease;
}

.dropdropdown-menu{
    width: 220px;
    position: absolute;
    left: 220px;
    list-style: none;
    text-align: start;
    transition: all 0.5s ease;
}

.dropdown-menu li{
    background: #302f2f;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: whitesmoke;
    vertical-align:middle;
}

.drop-dropdown-li{
    padding: 16px;
}

.dropdown-menu li:hover{
    background: #5b5b5b;
    transition: all 0.2s ease-out;
}

.nav-link a:hover, .nav-dropdown a:hover{
    background: transparent;
}

.drop-dropdown{
    cursor: default;
}

.drop-nav-link{
    width: 100%;
    padding: 16px;
    color: whitesmoke;
    text-decoration: none;
    background: transparent;
    margin-top: 0;
}

.active-dropdown-item{
    background:#017241 !important;
}

.active-dropdown-item:hover{
    background:#48b385 !important;
}

.fa-caret-right{
    float:right;
}

@media screen and (min-width: 1101px){
    .drop-nav-link{
        display: inline-block;
    }
}