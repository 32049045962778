.contact-picture{
    max-width: 100%;
    height: auto;
    margin-top: 50px;
    background: transparent;
}

.contact-subtitle-title{
    font-weight: bold;
    margin-left: 0px;
    margin-right: 0px;
    text-align: center;
    background: transparent;
    margin-top: 10px;
}

.contact-subtitle{
    margin-top: 3px;
    margin-left: 0px;
    margin-right: 0px;
    text-align: center;
    background: transparent;
}

.mobile-number{
    text-align: center;
    font-style: italic;
}

@media screen and (max-width: 1050px){
    .contact-picture{
        margin-top: 20px;
    }

    .contact-subtitle, .contact-subtitle-title{
        font-size: 0.8rem;
    }
}