.partner{
    width: 30vw;
    margin-top: 20px;
}

.social-media-icon{
    color: whitesmoke;
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
}

.social-media-icon:hover{
    color: #017241;
    cursor: pointer;
    transition: all 0.3s ease;
}

@media screen and (max-width: 960px){
    .partner{
        width: 35vw;
        margin-top: 20px;
    }
}

@media screen and (max-width: 700px){
    .partner{
        width: 60vw;
        margin-top: 20px;
    }
}