.head-image{
    padding-top: 25px;
    margin-left: 5px;
    margin-right: 5px;
    height: 48vmin;
    max-width: 100%;
}

.date{
    font-size: 0.9rem;
    font-style:italic;
    padding-bottom: 5px;
    text-align: right;
    margin-top: 10px;
}

@media screen and (max-width: 1050px){
    .date{
        font-size: 0.8rem;
        font-style:italic;
        padding-bottom: 5px;
    }
    .first-block{
        margin-top: 7px;
    }
}